import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import fastForwardLogo from "../../images/fast-forward.svg"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Services for Children" />

    <div className="masthead mb-5">
      <Container className="singleCol text-center">
        <h1 className="mb-0">Our Services for Children</h1>
      </Container>
    </div>
    
    <Container>
      <Row className="g-5">
        <Col lg={6}>
          <p className="text-center">
            <StaticImage
              src="../../images/girl-at-computer.jpg"
              loading="eager"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Young child sitting in front of a laptop computer"
            /> 
          </p> 
          <h2>Virtual and in-person speech and language services</h2>
          <p>
            
            Speech and language therapy sessions are conducted in-person and via Zoom. We provide an engaging, fun and interactive learning experience.
          </p>
          <p>
          Our speech therapists use a variety of tools tailored to each client's needs. We specifically address: articulation, language, voice, stuttering, social language,reading, writing, and executive functioning skills. 
          </p>
        </Col>
        <Col lg={6}>
          <p className="text-center">
            <StaticImage
              src="../../images/kid-with-blue-headphones-on-laptop.jpg"
              loading="eager"
              quality={95}
              height={342}
              formats={["auto", "webp", "avif"]}
              alt="Young child with headphones on sitting in front of a laptop"
            />  
          </p>
          <h2 className="mx-5" aria-label="Fast ForWord in Conjunction With Speech Therapy Sessions">
            <img src={fastForwardLogo} width="1000" height={130} alt="Fast ForWord logo" className="img-fluid" />
          </h2>
          <p>
          <a href="https://www.scilearn.com/program/">Fast ForWord®</a> helps children and adults improve:    
          </p>
          <ul>
            <li>Dyslexia</li>
            <li>Central Auditory Processing Deficits</li>
            <li>Language-based or reading difficulties</li>
            <li>Language/Articulation delays due to hearing loss.  </li>
          </ul>
          <p>
            When speech therapy sessions are combined with Fast ForWord®, the results are particularly effective. Results in reading, auditory processing and language comprehension can markedly improve.  
          </p>
        </Col>

        <Col lg={6}>
          <p className="text-center">
          <StaticImage
            src="../../images/controller.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Game Controller"
          />  
          </p>
          <h2>Communication Gaming Group</h2>
          <p>
          Our Minecraft gaming groups help kids work on their speech and language skills in a fun and engaging way. Sessions are tailored to each client’s strengths and needs. Kids won’t even realize they are learning!  
          </p>
        </Col>
        <Col lg={6}>
          <p className="text-center">
            <StaticImage
              src="../../images/parents-on-a-laptop.jpg"
              loading="eager"
              height={342}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Two adults looking at a laptop"
            />  
          </p>
          <h2>Parent Education</h2>
          <p>
            Parent education is a crucial part of any speech/language therapy, reading support, social skills or specialized program session. Parent education is typically included either within or after sessions, verbally or in written form, but we often find that what needs to be shared requires a dedicated session for a comprehensive understanding of the therapist’s observations, plan, and goals.  We want parents to have ample space for a real time conversation where they can ask questions and share their concerns. 
          </p>
          <p>
            Our speech/language therapists share their expertise and experience in these stand alone sessions, which can be scheduled either once, intermittently, or regularly, depending upon what is needed for each client. Support is provided both clinically and holistically. Our aim is to help parents understand our therapy approach and plans, integrate the parents’ goals, and listen to their concerns. These parent education and support sessions are available for all clients. Whether they are working on speech, language, literacy, reading skills, early intervention, language development, social skills, or articulation; or working with one of our specialized programs such as Fast ForWord and Gestalt Language Processing, we are attentive to the clinical and relational needs of each client. 
          </p>
        </Col>
        

        {/* <Col lg={6}>
          <p className="text-center">
          <StaticImage
            src="../../images/adult-business-man.jpg"
            loading="eager"
            width={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Adult business man"
          />  
          </p>
          <h2>Adults</h2>
          <p>
          Vibe speech therapists use their clinical expertise and experience in supporting adults in all matters related to speech and language: articulation, language, voice, accent modification, recovery from a stroke, head trauma, or as a supportive therapy while coping with a neurological disorder. We help clients, families and caregivers to support their loved ones in recovering their speech and language skills.   
          </p>
        </Col>

        <Col lg={6}>
          <p className="text-center">
          <StaticImage
            src="../../images/accent-mod-group.jpg"
            loading="eager"
            width={600}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="3 people sitting around a cafe table"
          />  
          </p>
          <h2>Accent Modification</h2>
          <p>
            When we learn another language, we try to perfect speech patterns, intonations, and non-verbal communication specific to that language, but we are often limited in reaching our goal of fluency. Accents sometimes make it difficult to communicate with other people, and can make work and other activities more challenging. 
          </p>
          <p>
            If you are seeking accent modification, you may be looking to have more successful business and personal relationships. People choose to modify their accent for public speaking, acting, giving clear and engaging presentations, improving business management skills and professional communication, or demonstrating improved leadership skills.   
          </p>
        </Col> */}
        
      </Row>
    </Container>
    
  </Layout>
)

export default IndexPage
